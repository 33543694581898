import { useQuery } from "urql";

import {
  CollateralStatusLogFilter,
  CollateralStatusLogOrderBy,
  GetCollateralStatusLogDocument,
} from "../../../graphql/generated.ts";
import { ApiHookProps } from "../types.ts";

interface Props
  extends ApiHookProps<CollateralStatusLogFilter, CollateralStatusLogOrderBy> {
  collateralId: number;
}

export const useCollateralStatusLog = ({
  collateralId,
  filter,
  orderBy,
  pause,
}: Props) => {
  const variables = {
    collateralId,
    filter,
    orderBy,
  };
  return useQuery({ query: GetCollateralStatusLogDocument, variables, pause });
};
