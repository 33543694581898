import pickBy from "lodash/pickBy";
import { DataTableSortStatus } from "mantine-datatable";

import {
  LimitFilter,
  LimitOrderBy,
  LimitStatusFilterOptions,
  LimitTypeFilterOptions,
} from "../../graphql/generated.ts";
import { getApiOrderBy } from "../../utils/api.ts";

export type CustomerLimitUtilizationFilterValue = {
  limitStatus?: LimitStatusFilterOptions[];
  limitType?: LimitTypeFilterOptions;
};

export const getCustomerLimitUtilizationApiSort = (
  tableSort: DataTableSortStatus
): LimitOrderBy => {
  const { customerName, ...apiSort } = getApiOrderBy(tableSort);
  return {
    ...apiSort,
    customer: customerName && { name: customerName },
  };
};

export const getCustomerLimitUtilizationApiFilter = (
  value: CustomerLimitUtilizationFilterValue
): LimitFilter => {
  return pickBy({
    limitType: value.limitType && {
      equals: value.limitType,
    },
    status: value.limitStatus?.length && { in: value.limitStatus },
  });
};
