import { useMemo } from "react";
import { Link } from "react-router-dom";

import { DatabaseMetadataPopover } from "../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import Spoiler from "../../../../components/Spoiler/Spoiler.tsx";
import {
  CreditUnderwriting,
  toHumanReadableCuLoiResultCategory,
} from "../../../../types/creditUnderwriting.ts";
import { LimitRequestDetail } from "../../../../types/limitRequest/limitRequest.ts";
import { toLocalDate } from "../../../../utils/date.ts";
import { toCurrencyPrice, toPercent } from "../../../../utils/number.ts";
import { toHumanReadable } from "../../../../utils/string.ts";
import { getUserDisplayName } from "../../../../utils/user.ts";

interface Props {
  limitRequest:
    | Pick<LimitRequestDetail, "cuAssigned" | "cuAssignedDate">
    | null
    | undefined;
  creditUnderwriting: CreditUnderwriting | null | undefined;
}

export default function useCreditUnderwritingCardData({
  limitRequest,
  creditUnderwriting,
}: Props) {
  return useMemo(() => {
    return [
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "LimitRequest",
              fieldName: "cuAssignedDate",
            }}
            position="right"
          >
            CU Assigned Date
          </DatabaseMetadataPopover>
        ),
        content:
          limitRequest?.cuAssignedDate &&
          toLocalDate(limitRequest.cuAssignedDate),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "LimitRequest",
              fieldName: "cuAssigned",
            }}
            position="right"
          >
            CU Assigned
          </DatabaseMetadataPopover>
        ),
        content: getUserDisplayName(limitRequest?.cuAssigned),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "CreditUnderwriting",
              fieldName: "clikResultReviewDate",
            }}
            position="right"
          >
            CLIK Result Review Date
          </DatabaseMetadataPopover>
        ),
        content:
          creditUnderwriting?.clikResultReviewDate &&
          toLocalDate(creditUnderwriting.clikResultReviewDate),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "CreditUnderwriting",
              fieldName: "clikResultCategory",
            }}
            position="right"
          >
            CLIK Result Category
          </DatabaseMetadataPopover>
        ),
        content:
          creditUnderwriting?.clikResultCategory &&
          toHumanReadable(creditUnderwriting.clikResultCategory),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "CreditUnderwriting",
              fieldName: "loiResultDate",
            }}
            position="right"
          >
            LOI Result Date
          </DatabaseMetadataPopover>
        ),
        content:
          creditUnderwriting?.loiResultDate &&
          toLocalDate(creditUnderwriting.loiResultDate),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "CreditUnderwriting",
              fieldName: "loiResultFile",
            }}
            position="right"
          >
            LOI Result
          </DatabaseMetadataPopover>
        ),
        content: creditUnderwriting?.loiResultFile?.url && (
          <Link to={creditUnderwriting?.loiResultFile?.url} target="_blank">
            Download
          </Link>
        ),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "CreditUnderwriting",
              fieldName: "loiResultCategory",
            }}
            position="right"
          >
            LOI Result Category
          </DatabaseMetadataPopover>
        ),
        content:
          creditUnderwriting?.loiResultCategory &&
          toHumanReadableCuLoiResultCategory(
            creditUnderwriting.loiResultCategory
          ),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "CreditUnderwriting",
              fieldName: "insuranceRequestDate",
            }}
            position="right"
          >
            Insurance Request Date
          </DatabaseMetadataPopover>
        ),
        content:
          creditUnderwriting?.insuranceRequestDate &&
          toLocalDate(creditUnderwriting.insuranceRequestDate),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "CreditUnderwriting",
              fieldName: "insuranceResultDate",
            }}
            position="right"
          >
            Insurance Result Date
          </DatabaseMetadataPopover>
        ),
        content:
          creditUnderwriting?.insuranceResultDate &&
          toLocalDate(creditUnderwriting.insuranceResultDate),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "CreditUnderwriting",
              fieldName: "insuranceResult",
            }}
            position="right"
          >
            Insurance Result
          </DatabaseMetadataPopover>
        ),
        content:
          creditUnderwriting?.insuranceResult &&
          toHumanReadable(creditUnderwriting.insuranceResult),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "CreditUnderwriting",
              fieldName: "insuranceLimitApproved",
            }}
            position="right"
          >
            Insurance Limit Recommended
          </DatabaseMetadataPopover>
        ),
        content: toCurrencyPrice(creditUnderwriting?.insuranceLimitApproved),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "CreditUnderwriting",
              fieldName: "visitDate",
            }}
            position="right"
          >
            Visit Date
          </DatabaseMetadataPopover>
        ),
        content: toLocalDate(creditUnderwriting?.visitDate),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "CreditUnderwriting",
              fieldName: "documentCollectionStartDate",
            }}
            position="right"
          >
            Document Collection Date
          </DatabaseMetadataPopover>
        ),
        content: toLocalDate(creditUnderwriting?.documentCollectionStartDate),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "CreditUnderwriting",
              fieldName: "documentCompletedDate",
            }}
            position="right"
          >
            Document Completed Date
          </DatabaseMetadataPopover>
        ),
        content: toLocalDate(creditUnderwriting?.documentCompletedDate),
      },

      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "CreditUnderwriting",
              fieldName: "analysisResultDate",
            }}
            position="right"
          >
            CU Analysis Result Date
          </DatabaseMetadataPopover>
        ),
        content: toLocalDate(creditUnderwriting?.analysisResultDate),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "CreditUnderwriting",
              fieldName: "analysisResult",
            }}
            position="right"
          >
            CU Analysis Result
          </DatabaseMetadataPopover>
        ),
        content:
          creditUnderwriting?.analysisResult &&
          toHumanReadable(creditUnderwriting.analysisResult),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "CreditUnderwriting",
              fieldName: "analysisFile",
            }}
            position="right"
          >
            CU Analysis
          </DatabaseMetadataPopover>
        ),
        content: creditUnderwriting?.analysisFile?.url && (
          <Link to={creditUnderwriting?.analysisFile?.url} target="_blank">
            Download
          </Link>
        ),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "CreditUnderwriting",
              fieldName: "limitRecommendation",
            }}
            position="right"
          >
            CU Limit Recommendation
          </DatabaseMetadataPopover>
        ),
        content: toCurrencyPrice(creditUnderwriting?.limitRecommendation),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "CreditUnderwriting",
              fieldName: "tenorRecommendation",
            }}
            position="right"
          >
            CU Tenor Recommendation
          </DatabaseMetadataPopover>
        ),
        content: creditUnderwriting?.tenorRecommendation,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "CreditUnderwriting",
              fieldName: "pricingRecommendation",
            }}
            position="right"
          >
            CU Pricing Recommendation
          </DatabaseMetadataPopover>
        ),
        content: toPercent(
          creditUnderwriting?.pricingRecommendation,
          "percentage"
        ),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "CreditUnderwriting",
              fieldName: "notes",
            }}
            position="right"
          >
            CU Notes
          </DatabaseMetadataPopover>
        ),
        content: creditUnderwriting?.notes && (
          <Spoiler maxStringLength={50} text={creditUnderwriting.notes} />
        ),
      },
    ];
  }, [creditUnderwriting, limitRequest]);
}
