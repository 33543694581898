import { Flex, Slider, Text } from "@mantine/core";
import { useCallback, useMemo } from "react";

import { DatabaseMetadataPopover } from "../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import { TableData, TableRow } from "../../../components/Table/types.ts";
import { CustomerLimitUtilization } from "../../../types/limit.ts";
import {
  calculatePercent,
  toCurrencyPrice,
  toPrice,
} from "../../../utils/number.ts";
import { toHumanReadable } from "../../../utils/string.ts";

interface Props {
  data: CustomerLimitUtilization[] | null | undefined;
}

export const useCustomersUtilizationTableData = ({
  data,
}: Props): TableData => {
  const columns = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "customerName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Customer",
            fieldName: "name",
          }}
        >
          Customer Name
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "limitName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Limit",
            fieldName: "limitName",
          }}
        >
          Limit Name
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "limitType",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Limit",
            fieldName: "limitType",
          }}
        >
          Limit Type
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "status",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Limit",
            fieldName: "status",
          }}
        >
          Limit Status
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "limitUtilization",
      title: "Limit Utilization",
      sortable: false,
    },
  ];

  const renderLimitColumn = useCallback((item: CustomerLimitUtilization) => {
    return (
      <Flex gap={20} w="100%" h="100%" justify="flex-end" direction="row">
        <Text w="40%">
          {toCurrencyPrice(item.totalPaid ?? 0)} /{" "}
          {toPrice(item.totalLimit ?? 0)}
        </Text>
        <Slider
          w="50%"
          maw="320px"
          value={item.totalPaid ?? 0}
          max={item.totalLimit}
          min={0}
          thumbSize={0.01}
          disabled
        />
        <Text w="10%">
          {calculatePercent(item.totalPaid ?? 0, item.totalLimit ?? 0)}%
        </Text>
      </Flex>
    );
  }, []);

  const rows = useMemo(() => {
    return data?.map((item: CustomerLimitUtilization): TableRow => {
      return {
        id: item.id,
        customerName: item.customer.name,
        limitName: item.limitName,
        limitType: toHumanReadable(item.limitType),
        status: toHumanReadable(item.status),
        limitUtilization: renderLimitColumn(item),
      };
    });
  }, [data, renderLimitColumn]);

  return [columns, rows];
};
