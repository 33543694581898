import { DataTableColumn } from "mantine-datatable";
import { DataTableColumnGroup } from "mantine-datatable/dist/types/DataTableColumnGroup";
import { ReactNode } from "react";

export type TableRow = Record<string, string | ReactNode>;

export type TableData = [DataTableColumn<TableRow>[], TableRow[] | undefined];
export type TableGroupData = [
  DataTableColumnGroup<TableRow>[],
  TableRow[] | undefined
];

export enum SortDirection {
  asc = "asc",
  desc = "desc",
}
