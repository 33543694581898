import isNil from "lodash/isNil";
import { useQuery } from "urql";

import {
  ClikIndividualEmploymentOrderBy,
  GetClikEmploymentDocument,
} from "../../../graphql/generated";

interface Props {
  subjectId: number | null | undefined;
  orderBy: ClikIndividualEmploymentOrderBy;
}

export function useClikEmployment({ subjectId, orderBy }: Props) {
  const variables = {
    filter: { subjectId: { equals: subjectId } },
    orderBy,
  };
  return useQuery({
    query: GetClikEmploymentDocument,
    variables,
    pause: isNil(subjectId),
  });
}
