import {
  LegalDocument as GqlGeneratedLegalDocument,
  LegalDocumentStatus,
  LegalDocumentType,
} from "../graphql/generated.ts";
import { toHumanReadable } from "../utils/string.ts";

export type LegalDocument = GqlGeneratedLegalDocument;

export const legalDocumentStatusOptions = Object.values(
  LegalDocumentStatus
).map((value) => {
  return { value, label: toHumanReadable(value) };
});

export const toHumanReadableLegalDocumentType = (value: LegalDocumentType) => {
  switch (value) {
    case LegalDocumentType.Ol:
      return "OL";
    case LegalDocumentType.Pks:
      return "PKS";
    case LegalDocumentType.Pg:
      return "PG";
    default:
      return toHumanReadable(value);
  }
};

export const legalDocumentTypeOptions = Object.values(LegalDocumentType).map(
  (value) => {
    return { value, label: toHumanReadableLegalDocumentType(value) };
  }
);
