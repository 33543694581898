import {
  Button,
  Flex,
  Input,
  Modal,
  NumberInput,
  ScrollArea,
  Select,
  SimpleGrid,
  Textarea,
  TextInput,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import {
  MS_EXCEL_MIME_TYPE,
  MS_WORD_MIME_TYPE,
  PDF_MIME_TYPE,
} from "@mantine/dropzone";
import { isNotEmpty, useForm } from "@mantine/form";
import { useCallback } from "react";

import Dropzone from "../../../../../components/Dropzone/Dropzone.tsx";
import SvgCalendar from "../../../../../components/Icons/Calendar";
import { yesNoOptions } from "../../../../../constants.ts";
import { FileResult, GiroType } from "../../../../../graphql/generated.ts";
import {
  Collateral,
  CollateralModalType,
  giroStatusOptions,
  giroTypeOptions,
} from "../../../../../types/collateral.ts";
import {
  booleanToString,
  stringToBoolean,
} from "../../../../../utils/boolean.ts";
import {
  parseStringToNumber,
  priceFormatter,
} from "../../../../../utils/number.ts";
import { useStyles } from "./GiroManagementModal.styles.ts";

interface Props {
  value?: Collateral | null | undefined;
  opened?: boolean;
  olNumbersList?: string[] | null;
  soNumbersList?: { id: number; saleOrderNumber: string }[] | null;
  onClose: () => void;
  onSubmit: (value: CollateralModalType) => void;
}

const GiroManagementModal = ({
  value,
  opened = false,
  olNumbersList,
  soNumbersList,
  onClose,
  onSubmit,
}: Props) => {
  const { classes } = useStyles();

  const soNumberOptions = (soNumbersList ?? []).map((item) => ({
    value: String(item.id),
    label: item.saleOrderNumber,
  }));

  const form = useForm({
    initialValues: {
      receiveDate: value?.receiveDate ? new Date(value.receiveDate) : undefined,
      giroType: value?.giroType,
      dueDate: value?.dueDate ? new Date(value.dueDate) : undefined,
      collateralBuyerNumber: value?.collateralBuyerNumber,
      bankName: value?.bankName,
      bankAccountNo: value?.bankAccountNo,
      accountHolderName: value?.accountHolderName,
      amount: value?.amount && Number(value.amount),
      olNo: value?.olNo,
      transactionId: value?.transaction && String(value.transaction.id),
      status: value?.status,
      giroAccountNoMatched: booleanToString(
        value?.giroAccountNoMatched,
        "formValue"
      ),
      collateralFile: value?.collateralFile,
      notes: value?.notes,
      giroDeviasi: booleanToString(value?.giroDeviasi, "formValue"),
      deviasiNotes: value?.deviasiNotes,
    },
    validate: {
      receiveDate: isNotEmpty("Field is required"),
      giroType: isNotEmpty("Field is required"),
      dueDate: (value, values) =>
        values.giroType !== GiroType.GiroCollateral
          ? isNotEmpty("Field is required")(value)
          : null,
      collateralBuyerNumber: isNotEmpty("Field is required"),
      bankName: isNotEmpty("Field is required"),
      bankAccountNo: isNotEmpty("Field is required"),
      accountHolderName: isNotEmpty("Field is required"),
      amount: isNotEmpty("Field is required"),
      status: isNotEmpty("Field is required"),
      giroAccountNoMatched: isNotEmpty("Field is required"),
    },
    validateInputOnBlur: true,
    clearInputErrorOnChange: true,
  });

  const handleClose = useCallback(() => {
    form.reset();
    onClose();
  }, [form, onClose]);

  const handleSubmit = useCallback(() => {
    if (!form.isValid()) {
      return;
    }

    onSubmit({
      ...form.values,
      transactionId:
        form.values.transactionId && Number(form.values.transactionId),
      giroAccountNoMatched: stringToBoolean(
        form.values.giroAccountNoMatched,
        true
      ),
      giroDeviasi: stringToBoolean(form.values.giroDeviasi, true),
      collateralFile: form.values.collateralFile?.filePath,
    } as CollateralModalType);
    handleClose();
  }, [form, handleClose, onSubmit]);

  const handleUploadFiles = (files: FileResult[]) => {
    form.setValues({ collateralFile: files[0] });
  };

  const handleRemoveFile = () => {
    form.setValues({ collateralFile: undefined });
  };

  return (
    <Modal
      opened={opened}
      title={value ? "Edit GIRO" : "Add GIRO"}
      closeButtonProps={{
        size: 24,
        iconSize: 24,
      }}
      size="auto"
      scrollAreaComponent={ScrollArea.Autosize}
      onClose={handleClose}
    >
      <Flex direction="column" gap={20} className={classes.contentWrapper}>
        <SimpleGrid
          cols={2}
          breakpoints={[{ maxWidth: "lg", cols: 1 }]}
          spacing={20}
          verticalSpacing={12}
        >
          <Flex direction="column" gap={12}>
            <DatePickerInput
              label="GIRO Received Date"
              placeholder="Select GIRO Received Date"
              icon={<SvgCalendar />}
              firstDayOfWeek={0}
              size="m"
              valueFormat="YYYY MMM DD"
              popoverProps={{ withinPortal: true }}
              clearable
              required
              {...form.getInputProps("receiveDate")}
            />
            <Select
              withinPortal
              required
              data={giroTypeOptions}
              label="Type"
              placeholder="Select Type"
              size="m"
              {...form.getInputProps("giroType")}
              onChange={(value) => {
                form.clearFieldError("dueDate");
                form.getInputProps("giroType").onChange(value);
              }}
            />
            <DatePickerInput
              required={form.values.giroType !== GiroType.GiroCollateral}
              label="Due Date"
              placeholder="Select Due Date"
              icon={<SvgCalendar />}
              firstDayOfWeek={0}
              size="m"
              valueFormat="YYYY MMM DD"
              clearable
              popoverProps={{ withinPortal: true }}
              {...form.getInputProps("dueDate")}
            />
            <TextInput
              required
              label="GIRO Buyer No"
              placeholder="Enter GIRO Buyer No"
              size="m"
              {...form.getInputProps("collateralBuyerNumber")}
            />
            <TextInput
              required
              label="Bank"
              placeholder="Enter Bank"
              size="m"
              {...form.getInputProps("bankName")}
            />
            <TextInput
              required
              label="Bank Account No"
              placeholder="Enter Bank Account No"
              size="m"
              {...form.getInputProps("bankAccountNo")}
            />
            <TextInput
              required
              label="Bank Account Holder Name"
              placeholder="Enter Bank Account Holder Name"
              size="m"
              {...form.getInputProps("accountHolderName")}
            />
            <NumberInput
              required
              label="GIRO Amount"
              placeholder="Enter GIRO Amount"
              size="m"
              precision={2}
              parser={parseStringToNumber}
              formatter={priceFormatter}
              min={0}
              hideControls
              {...form.getInputProps("amount")}
            />
          </Flex>
          <Flex direction="column" gap={12}>
            <Select
              withinPortal
              data={olNumbersList ?? []}
              label="OL No"
              placeholder="Select OL No"
              size="m"
              nothingFound="No options"
              {...form.getInputProps("olNo")}
            />
            <Select
              withinPortal
              data={soNumberOptions}
              label="SO No"
              placeholder="Select SO No"
              size="m"
              nothingFound="No options"
              {...form.getInputProps("transactionId")}
            />
            <Select
              withinPortal
              required
              data={giroStatusOptions}
              label="GIRO Status"
              placeholder="Select GIRO Status"
              size="m"
              {...form.getInputProps("status")}
            />
            <Input.Wrapper label="Upload File" size="m" w="100%">
              <Dropzone
                maxWidth="100%"
                value={
                  form.values.collateralFile ? [form.values.collateralFile] : []
                }
                accept={[
                  ...PDF_MIME_TYPE,
                  ...MS_EXCEL_MIME_TYPE,
                  ...MS_WORD_MIME_TYPE,
                ]}
                subLabel="Only pdf, xls, docs files up to 5MB are allowed"
                onUploadSuccess={handleUploadFiles}
                onRemove={handleRemoveFile}
              />
            </Input.Wrapper>
            <Textarea
              autosize
              label="Notes"
              placeholder="Enter Notes"
              minRows={2}
              maxRows={3}
              size="m"
              {...form.getInputProps("notes")}
            />
            <Select
              withinPortal
              dropdownPosition="top"
              required
              data={yesNoOptions}
              label="GIRO account number matched?"
              placeholder="Select GIRO account number matched"
              size="m"
              {...form.getInputProps("giroAccountNoMatched")}
            />
            <Select
              withinPortal
              required
              data={yesNoOptions}
              label="GIRO Deviasi"
              placeholder="Select GIRO Deviasi"
              size="m"
              {...form.getInputProps("giroDeviasi")}
            />
            <Textarea
              autosize
              label="Deviasi Notes"
              placeholder="Enter Deviasi Notes"
              minRows={2}
              maxRows={3}
              size="m"
              {...form.getInputProps("deviasiNotes")}
            />
          </Flex>
        </SimpleGrid>
        <Flex gap={20} justify="end">
          <Button
            variant="outlineBlue"
            uppercase
            type="reset"
            onClick={onClose}
          >
            cancel
          </Button>
          <Button
            variant="filledBlue"
            uppercase
            type="submit"
            disabled={!form.isValid()}
            onClick={handleSubmit}
          >
            {value ? "save" : "add"}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default GiroManagementModal;
