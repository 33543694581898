import { pickBy } from "lodash";
import { DataTableSortStatus } from "mantine-datatable";

import {
  LimitRequestFilter,
  LimitRequestOrderBy,
  OfferLetterOrderBy,
} from "../../../../graphql/generated.ts";
import { getApiOrderBy } from "../../../../utils/api.ts";
import { LimitRequestFiltersValue } from "./types.ts";

export const getLimitRequestApiFilter = (
  value: LimitRequestFiltersValue
): LimitRequestFilter => {
  const hasOlExpiredDate =
    !!value.olExpiredDate &&
    !!value.olExpiredDate[0] &&
    !!value.olExpiredDate[1];
  return pickBy({
    offerLetter: pickBy({
      olExpiredDate: !!value.olExpiredDate &&
        hasOlExpiredDate && {
          gte: value.olExpiredDate[0],
          lte: value.olExpiredDate[1],
        },
      olStatus: value.olStatus && {
        equals: value.olStatus,
      },
    }),
    limit: pickBy({
      status: value.limitStatus && {
        equals: value.limitStatus,
      },
      limitRequestType: value.limitRequestType && {
        equals: value.limitRequestType,
      },
    }),
  });
};

export const getLimitRequestApiSort = (
  tableSort: DataTableSortStatus
): LimitRequestOrderBy => {
  const { olNo, olExpiredDate, ...apiSort } = getApiOrderBy(tableSort);
  let offerLetter: OfferLetterOrderBy | undefined = undefined;
  if (olNo) {
    offerLetter = { olNo };
  }
  if (olExpiredDate) {
    offerLetter = { olExpiredDate };
  }
  return {
    ...apiSort,
    offerLetter,
  };
};
