import {
  CreditUnderwriting as GqlGeneratedCreditUnderwriting,
  CuAnalysisResult,
  CuCancelSubmissionReason,
  CuInsuranceResult,
  CuLoiResultCategory,
  CuResultCategory,
} from "../graphql/generated.ts";
import { toHumanReadable } from "../utils/string.ts";
import { Note } from "./note.ts";
import { User } from "./user.ts";

export type CreditUnderwriting = Omit<
  GqlGeneratedCreditUnderwriting,
  "limitRequestId" | "assigned" | "updatedBy" | "rejectNotes"
> & {
  assignedId?: number;
  assignedDate?: Date | undefined | null;
  updatedBy?: Pick<User, "id" | "name" | "email"> | undefined | null;
  rejectNotes: Pick<Note, "text" | "createdAt">[];
};

export const cuResultCategoryOptions = Object.values(CuResultCategory).map(
  (value) => {
    return { value, label: toHumanReadable(value) };
  }
);

export const toHumanReadableCuLoiResultCategory = (
  value: CuLoiResultCategory
) => {
  switch (value) {
    case CuLoiResultCategory.WithoutLoi:
      return "No/Without LOI";
    default:
      return toHumanReadable(value);
  }
};

export const cuLoiResultCategoryOptions = Object.values(
  CuLoiResultCategory
).map((value) => {
  return { value, label: toHumanReadableCuLoiResultCategory(value) };
});

export const cuInsuranceResultOptions = Object.values(CuInsuranceResult).map(
  (value) => {
    return { value, label: toHumanReadable(value) };
  }
);

export const cuAnalysisResultOptions = Object.values(CuAnalysisResult).map(
  (value) => {
    return { value, label: toHumanReadable(value) };
  }
);

export const cuCancelSubmissionOptions = Object.values(
  CuCancelSubmissionReason
).map((value) => {
  return { value, label: toHumanReadable(value) };
});
