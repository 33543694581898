import { Select } from "@mantine/core";
import { DataTableColumn } from "mantine-datatable";
import { useCallback, useMemo } from "react";

import { DatabaseMetadataPopover } from "../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import { TableData, TableRow } from "../../../../components/Table/types";
import {
  RolePermission,
  UpdateRMonAssignedInput,
} from "../../../../graphql/generated.ts";
import { useCurrentUserContext } from "../../../../providers/CurrentUserProvider.tsx";
import {
  LimitRequest,
  toHumanReadableTopProductCategory,
} from "../../../../types/limitRequest/limitRequest.ts";
import { UserOption } from "../../../../types/user.ts";
import { toLocalDate } from "../../../../utils/date";
import { toCurrencyPrice, toLocalNumber } from "../../../../utils/number";
import { toHumanReadable } from "../../../../utils/string";
import {
  getUserDisplayName,
  getUserOptions,
  hasPermission,
} from "../../../../utils/user.ts";

interface Props {
  data: LimitRequest[] | null | undefined;
  assignees: UserOption[] | null | undefined;
  onEditRMonAssigned: (id: number, input: UpdateRMonAssignedInput) => void;
}

export const useNewRRAnalysisTableData = ({
  data,
  assignees,
  onEditRMonAssigned,
}: Props): TableData => {
  const { user } = useCurrentUserContext();

  const hasEditAccess = hasPermission(
    user,
    RolePermission.CanEditRiskMonitoring
  );

  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "rrResultDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "RiskReview",
            fieldName: "analysisResultDate",
          }}
        >
          RR Result Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "limitName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "limitName",
          }}
        >
          Limit Name
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 300,
    },
    {
      accessor: "companyName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Customer",
            fieldName: "name",
          }}
        >
          Company Name
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 300,
    },
    {
      accessor: "salesName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Customer",
            fieldName: "salesName",
          }}
        >
          Sales Name
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "salesTeamName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Customer",
            fieldName: "salesTeamName",
          }}
        >
          Sales Team
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },

    {
      accessor: "limitRecommendation",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "RiskReview",
            fieldName: "limitRecommendation",
          }}
        >
          RR Limit Recommendation
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "tenorRecommendation",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "RiskReview",
            fieldName: "tenorRecommendation",
          }}
        >
          RR Tenor Recommendation
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "cuInsuranceLimit",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "CreditUnderwriting",
            fieldName: "insuranceLimitApproved",
          }}
        >
          Insurance Limit
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "limitType",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "limitRequestType",
          }}
        >
          Limit Type
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "topProductCategory",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "topProductCategory",
          }}
        >
          TOP Product Category
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "cuAssignedName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "cuAssignedId",
          }}
        >
          CU Assigned
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 250,
    },
    {
      accessor: "rrAssignedName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "rrAssignedId",
          }}
        >
          RR Assigned
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 250,
    },
    {
      accessor: "rMonAssignedName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "rMonAssignedId",
          }}
        >
          RMon Assigned
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 250,
    },
    {
      accessor: "requestStatus",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "requestStatus",
          }}
        >
          Request Status
        </DatabaseMetadataPopover>
      ),
    },
  ];

  const handleOnClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    //  stop event propagation (won't trigger parent's onClick)
    event.stopPropagation();
  }, []);

  const handleEditRMonAssigned = useCallback(
    (id: number, rMonAssignedId: number) => {
      onEditRMonAssigned(id, { rMonAssignedId, rMonAssignedDate: new Date() });
    },
    [onEditRMonAssigned]
  );

  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        id: item.id,
        rrResultDate: toLocalDate(item.riskReview?.analysisResultDate),
        limitName: item.limitName,
        companyName: item.company.name,
        salesName: item.company.salesName,
        salesTeamName: item.company.salesTeamName,
        limitRecommendation: toCurrencyPrice(
          item.riskReview?.limitRecommendation
        ),
        tenorRecommendation: toLocalNumber(
          item.riskReview?.tenorRecommendation
        ),
        cuInsuranceLimit: toCurrencyPrice(
          item.creditUnderwriting?.insuranceLimitApproved
        ),
        limitType:
          item.limitRequestType && toHumanReadable(item.limitRequestType),
        topProductCategory:
          item?.topProductCategory &&
          toHumanReadableTopProductCategory(item.topProductCategory),
        cuAssignedName: item.cuAssigned && getUserDisplayName(item.cuAssigned),
        rrAssignedName: item.rrAssigned && getUserDisplayName(item.rrAssigned),
        rMonAssignedName: hasEditAccess ? (
          <Select
            data={getUserOptions(assignees)}
            value={item.rMonAssigned?.id.toString()}
            withinPortal
            zIndex={100}
            size="s"
            onChange={(value) => handleEditRMonAssigned(item.id, Number(value))}
            onClick={(event) => handleOnClick(event)}
          />
        ) : (
          getUserDisplayName(item.rMonAssigned)
        ),
        requestStatus: toHumanReadable(item.requestStatus),
      };
    });
  }, [data, hasEditAccess, assignees, handleEditRMonAssigned, handleOnClick]);

  return [columns, rows];
};
