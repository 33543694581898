import { Flex, Modal, ScrollArea } from "@mantine/core";
import { notifications } from "@mantine/notifications";

import SvgWarning from "../../../../components/Icons/Warning.tsx";
import Table from "../../../../components/Table/Table.tsx";
import { useCollateralStatusLog } from "../../../../hooks/api/collateral/useCollateralStatusLog.ts";
import { useGiroManagementLogTableData } from "./hooks/useGiroManagementLogTableData.tsx";

interface Props {
  collateralId: number;
  opened?: boolean;
  onClose: () => void;
}

export const GiroManagementLogModal = ({
  collateralId,
  opened = false,
  onClose,
}: Props) => {
  const [{ data, fetching, error }] = useCollateralStatusLog({
    collateralId,
  });

  const [columns, rows] = useGiroManagementLogTableData({
    data: data?.collateralStatusLog,
  });

  if (error) {
    notifications.show({
      message: "Something went wrong while trying to fetch data.",
      icon: <SvgWarning />,
    });
    return;
  }

  return (
    <Modal
      opened={opened}
      title={"GIRO Log Details"}
      closeButtonProps={{
        size: 24,
        iconSize: 24,
      }}
      size="auto"
      scrollAreaComponent={ScrollArea.Autosize}
      onClose={onClose}
    >
      <Flex direction="column" gap={20}>
        <Table
          minWidth="30vw"
          noHeader
          withColumnBorders={false}
          columns={columns}
          rows={rows}
          loading={fetching}
        />
      </Flex>
    </Modal>
  );
};
