import { SelectItem } from "@mantine/core/lib/Select/types";
import { sortBy, without } from "lodash";

import {
  Collateral as GqlCollateral,
  CollateralStatus,
  GetCollateralsQuery,
  GetCollateralStatusLogQuery,
  GiroType,
} from "../graphql/generated.ts";
import { toHumanReadable } from "../utils/string.ts";

export type Collateral = GetCollateralsQuery["collaterals"]["data"][0];
export type CollateralStatusLog =
  GetCollateralStatusLogQuery["collateralStatusLog"][0];

export type RawCollateral = Omit<GqlCollateral, "id" | "createdAt">;

export type CollateralModalType = Omit<RawCollateral, "customerId">;

export const giroTypeOptions: SelectItem[] = sortBy(
  without(Object.values(GiroType), GiroType.Giro, GiroType.Check).map(
    (value) => ({
      value,
      label: toHumanReadable(value) as string,
    })
  ),
  "label"
);

export const giroStatusOptions = sortBy(
  without(Object.values(CollateralStatus), CollateralStatus.Reject).map(
    (value) => {
      return { value, label: toHumanReadable(value) };
    }
  ),
  "label"
);

export const collateralStatusOptions = [
  CollateralStatus.Confirm,
  CollateralStatus.Reject,
].map((value) => {
  return { value, label: toHumanReadable(value) };
});
