import {
  Accordion,
  ActionIcon,
  Box,
  Flex,
  SimpleGrid,
  Text,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { isNull, sortBy } from "lodash";
import { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import MultiFieldCard from "../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import SvgChevronLeft from "../../components/Icons/ChevronLeft.tsx";
import SvgWarning from "../../components/Icons/Warning.tsx";
import Layout from "../../components/Layout/Layout.tsx";
import Preloader from "../../components/Preloader/Preloader.tsx";
import Title from "../../components/Title/Title.tsx";
import { AppRoute } from "../../constants.ts";
import { useGetCustomerDirectorInfo } from "../../hooks/api/customer/useGetCustomerDirectorInfo.ts";
import { usePenguruses } from "../../hooks/api/limitRequest/pengurus/usePenguruses.ts";
import { useLimitRequest } from "../../hooks/api/limitRequest/useLimitRequest.ts";
import { useCustomerDirectorInfoCard } from "../ClikDataDetails/CompanyInfo/hooks/useCustomerDirectorInfoCard.tsx";
import { usePengurusesCardInfo } from "../ClikDataDetails/PengurusInfo/hooks/usePengurusCardInfo.tsx";
import CreditCommitteeCard from "./CreditCommitteeCard/CreditCommitteeCard.tsx";
import CreditUnderwritingCard from "./CreditUnderwritingCard/CreditUnderwritingCard.tsx";
import { useStyles } from "./LimitRequestDetail.styles.tsx";
import LimitRequestInfoCard from "./LimitRequestInfoCard.tsx";
import OfferingLetterCard from "./OfferingLetter/OfferingLetterCard.tsx";
import RiskReviewCard from "./RiskReviewCard/RiskReviewCard.tsx";

const LimitRequestDetailPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams() as { id: string };

  const navigate = useNavigate();

  const { classes } = useStyles({ variant: "blue" });

  const [{ data: limitRequestResponse, fetching, error }, refreshLimitRequest] =
    useLimitRequest({
      id,
    });
  const limitRequest = limitRequestResponse?.limitRequest;

  const [{ data: directorInfoData, fetching: fetchingDirectorInfo }] =
    useGetCustomerDirectorInfo({ customerId: limitRequest?.company?.id });
  const directorInfo = directorInfoData?.customerDirectorInfo;
  const directorInfoCard = useCustomerDirectorInfoCard({
    data: directorInfo,
  });

  const [{ data: pengurusesData, fetching: pengurusesFetching }] =
    usePenguruses({
      limitRequestId: limitRequest?.id,
    });
  const penguruses = sortBy(pengurusesData?.penguruses, "id");
  const pengurusesInfoCards = usePengurusesCardInfo({
    penguruses,
  });

  const handleBack = () => navigate(-1);

  const handleNavigateBuyerInformation = useCallback(() => {
    if (!limitRequest?.company?.clikDataId) {
      return;
    }
    navigate(`${AppRoute.ClikData}/${limitRequest.company.clikDataId}`);
  }, [limitRequest?.company.clikDataId, navigate]);

  const renderTitle = useCallback(() => {
    if (!limitRequest?.company) {
      return <Title>Limit Requested Details</Title>;
    }
    return (
      <Title>
        Limit Requested Details -{" "}
        <span
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={handleNavigateBuyerInformation}
        >
          {limitRequest.company.name}
        </span>
      </Title>
    );
  }, [handleNavigateBuyerInformation, limitRequest?.company]);

  if (error || isNull(limitRequest)) {
    navigate(AppRoute.ClikData);
    notifications.show({
      message: "Something went wrong while trying to fetch data.",
      icon: <SvgWarning />,
    });
  }

  return (
    <Layout>
      <Preloader loading={fetching} />
      <Flex direction="column" gap={{ base: 24, sm: 20 }}>
        <Flex gap={20}>
          <ActionIcon size="m" variant="outlineGolden" onClick={handleBack}>
            <SvgChevronLeft />
          </ActionIcon>
          {renderTitle()}
        </Flex>
        <SimpleGrid cols={3} spacing={20} verticalSpacing={8}>
          <LimitRequestInfoCard
            loading={fetching}
            limitRequest={limitRequest}
          />
        </SimpleGrid>
        <Accordion chevronPosition="left">
          <Accordion.Item value="pengurus">
            <Accordion.Control>
              <Flex gap={12} align="center">
                <Box className={classes.pin} />
                <Text className={classes.label}>Pengurus Info</Text>
              </Flex>
            </Accordion.Control>
            <Accordion.Panel>
              <SimpleGrid
                cols={1}
                verticalSpacing={16}
                breakpoints={[
                  { minWidth: "sm", cols: 2, verticalSpacing: 20 },
                  { minWidth: "lg", cols: 3, verticalSpacing: 20 },
                ]}
                spacing={20}
              >
                {pengurusesInfoCards?.map((item, index) => (
                  <MultiFieldCard
                    key={index}
                    {...item}
                    loading={pengurusesFetching}
                  />
                ))}
              </SimpleGrid>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
        <Accordion chevronPosition="left">
          <Accordion.Item value="directorInCharge">
            <Accordion.Control>
              <Flex gap={12} align="center">
                <Box className={classes.pin} />
                <Text className={classes.label}>Director In Charge</Text>
              </Flex>
            </Accordion.Control>
            <Accordion.Panel>
              <SimpleGrid
                cols={1}
                verticalSpacing={16}
                breakpoints={[
                  { minWidth: "sm", cols: 2, verticalSpacing: 20 },
                  { minWidth: "lg", cols: 3, verticalSpacing: 20 },
                ]}
                spacing={20}
              >
                <MultiFieldCard
                  loading={fetchingDirectorInfo}
                  customVariant={<></>}
                  items={directorInfoCard}
                />
              </SimpleGrid>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
        <SimpleGrid cols={1} spacing={20} verticalSpacing={8}>
          <CreditUnderwritingCard
            limitRequest={limitRequest}
            onRefreshLimitRequest={refreshLimitRequest}
          />
        </SimpleGrid>
        <SimpleGrid cols={3} spacing={20} verticalSpacing={8}>
          <RiskReviewCard
            limitRequest={limitRequest}
            onRefreshLimitRequest={refreshLimitRequest}
          />
          <CreditCommitteeCard
            limitRequest={limitRequest}
            onRefreshLimitRequest={refreshLimitRequest}
          />
          <OfferingLetterCard
            limitRequest={limitRequest}
            onRefreshLimitRequest={refreshLimitRequest}
          />
        </SimpleGrid>
      </Flex>
    </Layout>
  );
};

export default LimitRequestDetailPage;
