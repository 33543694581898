import { DataTableColumn } from "mantine-datatable";
import { useMemo } from "react";

import ActionIcon from "../../../../../components/ActionIcon/ActionIcon.tsx";
import { DatabaseMetadataPopover } from "../../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import SvgEdit from "../../../../../components/Icons/Edit.tsx";
import SvgVisibility from "../../../../../components/Icons/Visibility.tsx";
import Spoiler from "../../../../../components/Spoiler/Spoiler.tsx";
import { TableData, TableRow } from "../../../../../components/Table/types.ts";
import TableFilesDisplay from "../../../../../components/TableFilesDisplay/TableFilesDisplay.tsx";
import { Collateral } from "../../../../../types/collateral.ts";
import { booleanToString } from "../../../../../utils/boolean.ts";
import { toLocalDate } from "../../../../../utils/date.ts";
import { priceFormatter } from "../../../../../utils/number.ts";
import { toHumanReadable } from "../../../../../utils/string.ts";

interface Props {
  data: Collateral[] | null | undefined;
  canEdit: boolean;
  onOpenEditModal: (giroManagement: Collateral) => void;
  onOpenLogModal: (giroManagement: Collateral) => void;
}

export function useGiroManagementTableData({
  data,
  canEdit,
  onOpenEditModal,
  onOpenLogModal,
}: Props): TableData {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "collateralNumber",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Collateral",
            fieldName: "collateralNumber",
          }}
        >
          GIRO No
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 250,
    },
    {
      accessor: "receiveDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Collateral",
            fieldName: "receiveDate",
          }}
        >
          GIRO Received Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 200,
    },
    {
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Collateral",
            fieldName: "giroType",
          }}
        >
          Type
        </DatabaseMetadataPopover>
      ),
      accessor: "giroType",
      sortable: true,
      width: 230,
    },
    {
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Collateral",
            fieldName: "dueDate",
          }}
        >
          Due Date
        </DatabaseMetadataPopover>
      ),
      accessor: "dueDate",
      sortable: true,
      width: 200,
    },
    {
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Collateral",
            fieldName: "collateralBuyerNumber",
          }}
        >
          GIRO Buyer No
        </DatabaseMetadataPopover>
      ),
      accessor: "collateralBuyerNumber",
      sortable: true,
      width: 230,
    },
    {
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Collateral",
            fieldName: "bankName",
          }}
        >
          Bank
        </DatabaseMetadataPopover>
      ),
      accessor: "bankName",
      sortable: true,
      width: 230,
    },
    {
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Collateral",
            fieldName: "bankAccountNo",
          }}
        >
          Bank Account No
        </DatabaseMetadataPopover>
      ),
      accessor: "bankAccountNo",
      sortable: true,
      width: 230,
    },
    {
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Collateral",
            fieldName: "accountHolderName",
          }}
        >
          Bank Account Holder Name
        </DatabaseMetadataPopover>
      ),
      accessor: "accountHolderName",
      sortable: true,
      width: 230,
    },
    {
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Collateral",
            fieldName: "amount",
          }}
        >
          GIRO Amount
        </DatabaseMetadataPopover>
      ),
      accessor: "amount",
      sortable: true,
      width: 230,
    },
    {
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Collateral",
            fieldName: "olNo",
          }}
        >
          OL No
        </DatabaseMetadataPopover>
      ),
      accessor: "olNo",
      sortable: true,
      width: 230,
    },
    {
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Collateral",
            fieldName: "transaction",
          }}
        >
          SO No
        </DatabaseMetadataPopover>
      ),
      accessor: "soNo",
      sortable: true,
      width: 230,
    },
    {
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Collateral",
            fieldName: "status",
          }}
        >
          GIRO Status
        </DatabaseMetadataPopover>
      ),
      accessor: "status",
      sortable: true,
      width: 230,
    },
    {
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Collateral",
            fieldName: "collateralFile",
          }}
        >
          File
        </DatabaseMetadataPopover>
      ),
      accessor: "collateralFile",
    },
    {
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Collateral",
            fieldName: "notes",
          }}
        >
          Notes
        </DatabaseMetadataPopover>
      ),
      accessor: "notes",
      width: 300,
    },
    {
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Collateral",
            fieldName: "giroAccountNoMatched",
          }}
        >
          GIRO Account Number Matched
        </DatabaseMetadataPopover>
      ),
      accessor: "giroAccountNoMatched",
      sortable: false,
    },
    {
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Collateral",
            fieldName: "giroDeviasi",
          }}
        >
          GIRO Deviasi
        </DatabaseMetadataPopover>
      ),
      accessor: "giroDeviasi",
      sortable: false,
    },
    {
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Collateral",
            fieldName: "deviasiNotes",
          }}
        >
          Deviasi Notes
        </DatabaseMetadataPopover>
      ),
      accessor: "deviasiNotes",
      sortable: false,
      width: 300,
    },
    {
      accessor: "actions",
      title: "Actions",
      hidden: !canEdit,
    },
    {
      accessor: "logs",
      title: "GIRO Log",
    },
  ];

  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        id: item.id,
        collateralNumber: item.collateralNumber,
        receiveDate: toLocalDate(item.receiveDate),
        giroType: toHumanReadable(item.giroType),
        dueDate: toLocalDate(item.dueDate),
        collateralBuyerNumber: item.collateralBuyerNumber,
        bankName: item.bankName,
        bankAccountNo: item.bankAccountNo,
        accountHolderName: item.accountHolderName,
        amount: priceFormatter(item.amount),
        olNo: item.olNo,
        soNo: item.transaction?.saleOrderNumber,
        status: toHumanReadable(item.status),
        collateralFile: item.collateralFile && (
          <TableFilesDisplay files={[item.collateralFile]} />
        ),
        notes: item.notes && (
          <Spoiler maxStringLength={300} text={item.notes} />
        ),
        giroAccountNoMatched: booleanToString(item.giroAccountNoMatched),
        giroDeviasi: booleanToString(item.giroDeviasi),
        deviasiNotes: item.deviasiNotes && (
          <Spoiler maxStringLength={300} text={item.deviasiNotes} />
        ),
        actions: (
          <ActionIcon onClick={() => onOpenEditModal(item)}>
            <SvgEdit />
          </ActionIcon>
        ),
        logs: (
          <ActionIcon onClick={() => onOpenLogModal(item)}>
            <SvgVisibility />
          </ActionIcon>
        ),
      };
    });
  }, [data, onOpenEditModal, onOpenLogModal]);

  return [columns, rows];
}
