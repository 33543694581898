import { useQuery } from "urql";

import {
  GetCustomerLimitUtilizationDocument,
  IntFilterInput,
  LimitFilter,
  LimitOrderBy,
} from "../../../graphql/generated.ts";
import { getApiPaginationData } from "../../../utils/api.ts";
import { ApiHookProps } from "../types.ts";

interface Props extends ApiHookProps<LimitFilter, LimitOrderBy> {
  utilization: IntFilterInput;
}

export const useCustomerLimitUtilization = ({
  filter,
  utilization,
  orderBy,
  page,
}: Props) => {
  const pagination = getApiPaginationData(page);
  const variables = {
    filter,
    utilization,
    orderBy,
    skip: pagination?.skip,
    take: pagination?.take,
  };
  return useQuery({ query: GetCustomerLimitUtilizationDocument, variables });
};
