import { pickBy } from "lodash";

import {
  FileResult,
  LegalDocumentFilter,
  LegalDocumentStatus,
  UpdateLegalDocumentInput,
} from "../../../../graphql/generated.ts";
import {
  LegalDocument,
  legalDocumentStatusOptions,
} from "../../../../types/legalDocument.ts";
import { LegalDocumentFiltersValue } from "./types.ts";

export const getLegalDocumentApiFilter = (
  value: LegalDocumentFiltersValue
): LegalDocumentFilter => {
  const hasLegalDocumentDueDatePeriod =
    !!value.dueDatePeriod &&
    !!value.dueDatePeriod[0] &&
    !!value.dueDatePeriod[1];
  return pickBy({
    pksStatus: value.status && { equals: value.status },
    expiryDate: !!value.dueDatePeriod &&
      hasLegalDocumentDueDatePeriod && {
        gte: value.dueDatePeriod[0],
        lte: value.dueDatePeriod[1],
      },
  });
};

export const formatLegalDocumentToUpdateLegalDocumentInput = (
  data: LegalDocument
): UpdateLegalDocumentInput => {
  return {
    ...data,
    documentFile: data.documentFile.filePath,
    signedDocumentFile: data.signedDocumentFile?.filePath,
  };
};

export const getLegalDocumentStatusOptions = (
  active: FileResult | null | undefined
) =>
  legalDocumentStatusOptions.map((item) =>
    item.value === LegalDocumentStatus.Active
      ? { ...item, disabled: !active }
      : item
  );
