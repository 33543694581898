import { Flex } from "@mantine/core";
import { notifications } from "@mantine/notifications";

import SvgWarning from "../../components/Icons/Warning.tsx";
import SectionTitle from "../../components/Section/SectionTitle/SectionTitle.tsx";
import Table from "../../components/Table/Table.tsx";
import { LimitStatusFilterOptions } from "../../graphql/generated.ts";
import { useLimitUtilization } from "../../hooks/api/limit/useLimitUtilization.ts";
import { useLimitUtilizationTableData } from "./hooks/useLimitUtilizationTableData.tsx";

const LimitUtilizationTable = () => {
  const [{ data, fetching, error }] = useLimitUtilization({
    filter: {
      status: {
        in: [
          LimitStatusFilterOptions.Active,
          LimitStatusFilterOptions.Freeze,
          LimitStatusFilterOptions.Inactive,
        ],
      },
    },
  });
  const [groups, rows] = useLimitUtilizationTableData({
    data: data?.limits.data,
  });

  if (error) {
    notifications.show({
      message:
        "Something went wrong while trying to fetch Limit Utilization data",
      icon: <SvgWarning />,
    });
  }

  return (
    <Flex direction="column" gap={20}>
      <SectionTitle variant="bronze">Limit Utilization</SectionTitle>
      <Table groups={groups} rows={rows} minWidth="100%" loading={fetching} />
    </Flex>
  );
};

export default LimitUtilizationTable;
