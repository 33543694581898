import { Button, Select, SimpleGrid } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { DatesRangeValue } from "@mantine/dates/lib/types/DatePickerValue";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";

import SvgCalendar from "../../../../components/Icons/Calendar.tsx";
import SvgPlus from "../../../../components/Icons/Plus.tsx";
import SvgWarning from "../../../../components/Icons/Warning.tsx";
import SectionTitle from "../../../../components/Section/SectionTitle/SectionTitle.tsx";
import Table from "../../../../components/Table/Table.tsx";
import { SortDirection } from "../../../../components/Table/types.ts";
import {
  CreateLegalDocumentInput,
  LegalDocumentFilter,
  LegalDocumentOrderBy,
  OrderBy,
  RolePermission,
  UpdateLegalDocumentInput,
} from "../../../../graphql/generated.ts";
import { useCreateLegalDocument } from "../../../../hooks/api/legalDocument/useCreateLegalDocument.ts";
import { useLegalDocuments } from "../../../../hooks/api/legalDocument/useLegalDocuments.ts";
import { useUpdateLegalDocument } from "../../../../hooks/api/legalDocument/useUpdateLegalDocument.ts";
import { useMutationNotificationWrapper } from "../../../../hooks/useMutationNotificationWrapper.tsx";
import { useCurrentUserContext } from "../../../../providers/CurrentUserProvider.tsx";
import {
  LegalDocument,
  legalDocumentStatusOptions,
} from "../../../../types/legalDocument.ts";
import { getApiOrderBy } from "../../../../utils/api.ts";
import { getPagesCount } from "../../../../utils/pagination.ts";
import { hasPermission } from "../../../../utils/user.ts";
import { useLegalDocumentTableData } from "./hooks/useLegalDocumentTableData.tsx";
import LegalDocumentModal, {
  LegalDocumentModalSubmitValue,
} from "./LegalDocumentModal.tsx";
import { LegalDocumentFiltersValue } from "./types.ts";
import { getLegalDocumentApiFilter } from "./utils.ts";

interface Props {
  customerId: number | null | undefined;
}

export default function LegalDocumentTable({ customerId }: Props) {
  const [filter, setFilter] = useState<LegalDocumentFilter>({});
  const [orderBy, setOrderBy] = useState<LegalDocumentOrderBy>({
    documentDate: OrderBy.Asc,
  });
  const [page, setPage] = useState<number>(1);
  const [{ data, fetching, error }, refresh] = useLegalDocuments({
    filter: { ...filter, customerId: { equals: customerId } },
    orderBy,
    page,
  });

  const { user: currentUser } = useCurrentUserContext();

  const documentList: LegalDocument[] | undefined | null =
    data?.legalDocuments?.data;
  const pageCount = getPagesCount(data?.legalDocuments?.total);

  const [, createLegalDocument] = useMutationNotificationWrapper(
    useCreateLegalDocument(),
    {
      success: {
        message: "Legal Document has been added successfully.",
      },
    }
  );

  const [, updateLegalDocument] = useMutationNotificationWrapper(
    useUpdateLegalDocument(),
    {
      success: {
        message: "Legal Document has been updated successfully.",
      },
    }
  );

  const hasEditAccess = hasPermission(
    currentUser,
    RolePermission.CanEditBuyerInfoLimitInfoPksLists
  );

  const handleLegalDocumentModalOpen = (document: LegalDocument) => {
    setSelectedLegalDocument(document);
    legalDocumentModalOpen();
  };

  const handleUpdate = useCallback(
    (id: number, input: UpdateLegalDocumentInput) => {
      updateLegalDocument({
        id,
        input,
      }).then((result) => {
        if (!result.error) {
          refresh();
        }
      });
    },
    [refresh, updateLegalDocument]
  );

  const [columns, rows] = useLegalDocumentTableData({
    data: documentList,
    canEdit: hasEditAccess,
    onEdit: handleUpdate,
    onOpenEditModal: handleLegalDocumentModalOpen,
  });

  const [selectedLegalDocument, setSelectedLegalDocument] =
    useState<LegalDocument | null>();

  const [
    legalDocumentModalOpened,
    { open: legalDocumentModalOpen, close: legalDocumentModalClose },
  ] = useDisclosure(false);

  const handleLegalDocumentModalClose = () => {
    setSelectedLegalDocument(null);
    legalDocumentModalClose();
  };

  const handleCreate = useCallback(
    (input: CreateLegalDocumentInput) => {
      createLegalDocument({
        input: {
          ...input,
          customerId,
        } as CreateLegalDocumentInput,
      }).then((result) => {
        if (!result.error) {
          refresh();
        }
      });
    },
    [createLegalDocument, customerId, refresh]
  );

  const handleLegalDocumentModalSubmit = (
    input: LegalDocumentModalSubmitValue
  ) => {
    if (selectedLegalDocument) {
      handleUpdate(selectedLegalDocument.id, input as UpdateLegalDocumentInput);
    } else {
      handleCreate({
        ...input,
        customerId,
      } as CreateLegalDocumentInput);
    }
    handleLegalDocumentModalClose();
  };

  const handleSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getApiOrderBy(sort));
    },
    [setOrderBy]
  );

  const handleFilterChange = useCallback(
    (
      key: keyof LegalDocumentFiltersValue,
      value: string | DatesRangeValue | null
    ) => {
      setFilter(getLegalDocumentApiFilter({ [key]: value }));
    },
    []
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  if (!customerId) {
    return;
  }

  if (error) {
    notifications.clean();
    notifications.show({
      message:
        "Something went wrong while trying to fetch Legal Document data.",
      icon: <SvgWarning />,
    });
  }

  return (
    <>
      <SectionTitle variant="bronze">Legal Document List</SectionTitle>
      {hasEditAccess && (
        <Button
          uppercase
          variant="filledGolden"
          leftIcon={<SvgPlus />}
          onClick={legalDocumentModalOpen}
        >
          Add
        </Button>
      )}
      <SimpleGrid cols={3} spacing={20} verticalSpacing={8}>
        <Select
          size="m"
          clearable
          label="Status"
          placeholder="Select Status"
          data={legalDocumentStatusOptions}
          onChange={(value: string) => handleFilterChange("status", value)}
        />
        <DatePickerInput
          type="range"
          label="Due Date Period"
          placeholder="Select Due Date Period"
          icon={<SvgCalendar />}
          firstDayOfWeek={0}
          size="m"
          clearable
          onChange={(value: DatesRangeValue) =>
            handleFilterChange("dueDatePeriod", value)
          }
        />
      </SimpleGrid>
      <Table
        columns={columns}
        rows={rows}
        defaultSort={{
          columnAccessor: "createdAt",
          direction: SortDirection.asc,
        }}
        loading={fetching}
        pagination={{
          pageCount: pageCount,
          page: page,
          onPageChange: handlePageChange,
        }}
        onSortChange={handleSortChange}
      />

      {legalDocumentModalOpened && (
        <LegalDocumentModal
          value={selectedLegalDocument}
          opened={legalDocumentModalOpened}
          onClose={handleLegalDocumentModalClose}
          onSubmit={handleLegalDocumentModalSubmit}
        />
      )}
    </>
  );
}
