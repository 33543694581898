import { Flex, MultiSelect, Select, SimpleGrid } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";

import SvgWarning from "../../components/Icons/Warning.tsx";
import SectionTitle from "../../components/Section/SectionTitle/SectionTitle.tsx";
import Table from "../../components/Table/Table.tsx";
import {
  LimitStatusFilterOptions,
  OrderBy,
  TransactionHistoryOrderBy,
} from "../../graphql/generated.ts";
import { useCustomerLimitUtilization } from "../../hooks/api/limit/useCustomerLimitUtilization.ts";
import { limitTypeOptions } from "../../types/limit.ts";
import { getPagesCount } from "../../utils/pagination.ts";
import { useCustomersUtilizationTableData } from "./hooks/useCustomersUtilizationTableData.tsx";
import {
  CustomerLimitUtilizationFilterValue,
  getCustomerLimitUtilizationApiFilter,
  getCustomerLimitUtilizationApiSort,
} from "./utils.ts";

const CustomersUtilizationOver80Table = () => {
  const [filter, setFilter] = useState<CustomerLimitUtilizationFilterValue>({});
  const apiFilter = getCustomerLimitUtilizationApiFilter(filter);
  const [page, setPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<TransactionHistoryOrderBy>({
    customer: { name: OrderBy.Asc },
  });

  const [{ data, fetching, error }] = useCustomerLimitUtilization({
    filter: {
      ...apiFilter,
      status: apiFilter.status ?? {
        in: [
          LimitStatusFilterOptions.Active,
          LimitStatusFilterOptions.Freeze,
          LimitStatusFilterOptions.Inactive,
        ],
      },
    },
    orderBy,
    page,
    utilization: { gte: 80 },
  });

  const [columns, rows] = useCustomersUtilizationTableData({
    data: data?.limits.data,
  });

  const pageCount = getPagesCount(data?.limits.total);

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const handleOnSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getCustomerLimitUtilizationApiSort(sort));
    },
    [setOrderBy]
  );

  const handleFilterChange = useCallback(
    (key: keyof CustomerLimitUtilizationFilterValue, value: unknown) => {
      setFilter((prevFilter) => {
        return { ...prevFilter, [key]: value };
      });
    },
    []
  );

  if (error) {
    notifications.show({
      message:
        "Something went wrong while trying to fetch Customer Limit Utilization data",
      icon: <SvgWarning />,
    });
  }

  return (
    <Flex direction="column" gap={20}>
      <SectionTitle variant="bronze">
        List of customers with Utilization &gt;80%
      </SectionTitle>
      <SimpleGrid
        cols={4}
        breakpoints={[{ maxWidth: "sm", cols: 2 }]}
        spacing={20}
        verticalSpacing={12}
        pb={8}
      >
        <Select
          size="m"
          clearable
          label="Limit Type"
          placeholder="Select Limit Type"
          data={limitTypeOptions}
          onChange={(value) => handleFilterChange("limitType", value)}
        />
        <MultiSelect
          size="m"
          clearable
          label="Limit Status"
          placeholder="Select Limit Status"
          data={[
            { value: LimitStatusFilterOptions.Active, label: "Active" },
            { value: LimitStatusFilterOptions.Freeze, label: "Freeze" },
            { value: LimitStatusFilterOptions.Inactive, label: "Inactive" },
          ]}
          onChange={(value) => handleFilterChange("limitStatus", value)}
        />
      </SimpleGrid>
      <Table
        columns={columns}
        rows={rows}
        minWidth="100%"
        loading={fetching}
        pagination={{
          pageCount: pageCount,
          page: page,
          onPageChange: handlePageChange,
        }}
        onSortChange={handleOnSortChange}
      />
    </Flex>
  );
};

export default CustomersUtilizationOver80Table;
