import { useQuery } from "urql";

import {
  GetLegalDocumentListDocument,
  LegalDocumentFilter,
  LegalDocumentOrderBy,
} from "../../../graphql/generated.ts";
import { getApiPaginationData } from "../../../utils/api.ts";
import { ApiHookProps } from "../types.ts";

export const useLegalDocuments = ({
  filter,
  orderBy,
  page,
}: ApiHookProps<LegalDocumentFilter, LegalDocumentOrderBy>) => {
  const pagination = getApiPaginationData(page);
  const variables = {
    filter,
    orderBy,
    skip: pagination?.skip,
    take: pagination?.take,
  };
  return useQuery({ query: GetLegalDocumentListDocument, variables });
};
