import {
  Button,
  Flex,
  Modal,
  ScrollArea,
  Select,
  SimpleGrid,
  Textarea,
  TextInput,
} from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { useCallback } from "react";

import { CreatePaymasterInput } from "../../graphql/generated.ts";
import { CustomerOption } from "../../types/customer.ts";
import {
  paymasterCreditControlOptions,
  paymasterFinalScoreOptions,
  paymasterHistoricalPaymentOptions,
} from "../../types/paymaster.ts";
import { getCompanyOptions } from "../../utils/company.ts";

interface Props {
  customers: CustomerOption[];
  opened?: boolean;
  onClose: () => void;
  onSubmit: (value: CreatePaymasterInput) => void;
}

export const CreatePaymasterModal = ({
  customers,
  opened = false,
  onClose,
  onSubmit,
}: Props) => {
  const form = useForm({
    initialValues: {
      customerId: undefined,
      historicalPayment: undefined,
      historicalPaymasterRMon: undefined,
      historicalCreditControl: undefined,
      finalScore: undefined,
      name: undefined,
      notesRMon: undefined,
    },
    validate: {
      customerId: isNotEmpty("Customer Name is required"),
    },
    validateInputOnBlur: true,
    clearInputErrorOnChange: true,
  });

  const handleClose = useCallback(() => {
    form.reset();
    onClose();
  }, [form, onClose]);

  const handleSubmit = useCallback(() => {
    if (!form.isValid()) {
      return;
    }

    onSubmit({
      ...form.values,
      customerId: Number(form.values.customerId),
    } as CreatePaymasterInput);
    handleClose();
  }, [form, handleClose, onSubmit]);

  return (
    <>
      <Modal
        opened={opened}
        title="Add Paymaster"
        closeButtonProps={{
          size: 24,
          iconSize: 24,
        }}
        size="xl"
        scrollAreaComponent={ScrollArea.Autosize}
        onClose={handleClose}
      >
        <Flex direction="column" gap={20}>
          <SimpleGrid
            cols={2}
            breakpoints={[{ maxWidth: "lg", cols: 1 }]}
            spacing={20}
            verticalSpacing={12}
          >
            <Flex direction="column" gap={12}>
              <TextInput
                label="Paymaster Name"
                placeholder="Enter Paymaster Name"
                size="m"
                {...form.getInputProps("name")}
              />
              <Select
                label="Historical Payment Customer to bababos"
                placeholder="Select Historical Payment"
                size="m"
                data={paymasterHistoricalPaymentOptions}
                {...form.getInputProps("historicalPayment")}
              />
              <Select
                required
                size="m"
                searchable
                label="Customer Name"
                placeholder="Select Customer Name"
                data={getCompanyOptions(customers)}
                {...form.getInputProps("customerId")}
              />
              <Select
                label="Historical Paymaster (Remarks from RMon)"
                placeholder="Select Historical Payment"
                size="m"
                data={paymasterHistoricalPaymentOptions}
                {...form.getInputProps("historicalPaymasterRMon")}
              />
            </Flex>
            <Flex direction="column" gap={12}>
              <Select
                label="Historical Credit Control (Remarks from Risk Assurance)"
                placeholder="Select Historical Credit Control"
                size="m"
                data={paymasterCreditControlOptions}
                {...form.getInputProps("historicalCreditControl")}
              />
              <Select
                label="Final Score"
                placeholder="Select Final Score"
                size="m"
                data={paymasterFinalScoreOptions}
                {...form.getInputProps("finalScore")}
              />
              <Textarea
                autosize
                label="Remarks from RMon"
                placeholder="Enter Remarks"
                minRows={3}
                size="m"
                {...form.getInputProps("notesRMon")}
              />
            </Flex>
          </SimpleGrid>
          <Flex gap={20} justify="end">
            <Button
              variant="outlineBlue"
              uppercase
              type="reset"
              onClick={onClose}
            >
              cancel
            </Button>
            <Button
              variant="filledBlue"
              uppercase
              type="submit"
              disabled={!form.isValid()}
              onClick={handleSubmit}
            >
              add
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};
