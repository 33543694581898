import { Flex } from "@mantine/core";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";

import SectionTitle from "../../../../components/Section/SectionTitle/SectionTitle.tsx";
import Table from "../../../../components/Table/Table.tsx";
import { SortDirection } from "../../../../components/Table/types.ts";
import { ClikAddressOrderBy, OrderBy } from "../../../../graphql/generated.ts";
import { useClikEmployment } from "../../../../hooks/api/clikResult/useClikEmployment.ts";
import { getApiOrderBy } from "../../../../utils/api.ts";
import { useCREmploymentTableData } from "./hooks/useCREmploymentTableData.tsx";

interface Props {
  subjectId: number | undefined;
}

const ClikResultEmployment = ({ subjectId }: Props) => {
  const [orderBy, setOrderBy] = useState<ClikAddressOrderBy>({
    id: OrderBy.Desc,
  });

  const [{ data, fetching }] = useClikEmployment({
    subjectId,
    orderBy,
  });

  const clikEmployment = data?.clikEmployment ?? [];

  const [columns, rows] = useCREmploymentTableData({
    data: clikEmployment,
  });

  const handleSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getApiOrderBy(sort));
    },
    [setOrderBy]
  );

  return (
    <Flex direction="column" gap={16}>
      <SectionTitle variant="bronze">Employment</SectionTitle>
      <Table
        loading={fetching}
        columns={columns}
        rows={rows}
        minWidth="100%"
        defaultSort={{
          columnAccessor: "id",
          direction: SortDirection.asc,
        }}
        onSortChange={handleSortChange}
      />
    </Flex>
  );
};

export default ClikResultEmployment;
