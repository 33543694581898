import { DataTableColumn } from "mantine-datatable";
import { useMemo } from "react";

import { DatabaseMetadataPopover } from "../../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import { TableData, TableRow } from "../../../../../components/Table/types.ts";
import { ClikIndividualEmployment } from "../../../../../graphql/generated.ts";
import { toLocalDate } from "../../../../../utils/date.ts";
import { getCRCurrentFlagState } from "../../utils.ts";

interface Props {
  data: ClikIndividualEmployment[];
}

export function useCREmploymentTableData({ data }: Props): TableData {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "current",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikIndividualEmployment",
            fieldName: "current",
          }}
        >
          State
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "200px",
    },
    {
      accessor: "occupation",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikIndividualEmployment",
            fieldName: "occupation",
          }}
        >
          Occupation
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "workplace",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikIndividualEmployment",
            fieldName: "workplace",
          }}
        >
          Workplace
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "employerSector",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikIndividualEmployment",
            fieldName: "employerSector",
          }}
        >
          Employer Sector
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "workplaceAddress",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikIndividualEmployment",
            fieldName: "workplaceAddress",
          }}
        >
          Workplace Address
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "lastUpdatedDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikIndividualEmployment",
            fieldName: "lastUpdatedDate",
          }}
        >
          Last Update Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "230px",
    },
  ];

  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        id: item.id,
        current: getCRCurrentFlagState(item.current),
        occupation: item.occupation,
        workplace: item.workplace,
        employerSector: item.employerSector,
        workplaceAddress: item.workplaceAddress,
        lastUpdatedDate:
          item.lastUpdatedDate && toLocalDate(item.lastUpdatedDate),
      };
    });
  }, [data]);

  return [columns, rows];
}
