import {
  Button,
  Flex,
  Input,
  Modal,
  ScrollArea,
  Select,
  TextInput,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { isNotEmpty, useForm } from "@mantine/form";
import { useCallback } from "react";

import Dropzone from "../../../../components/Dropzone/Dropzone.tsx";
import SvgCalendar from "../../../../components/Icons/Calendar.tsx";
import {
  CreateLegalDocumentInput,
  FileResult,
} from "../../../../graphql/generated.ts";
import {
  LegalDocument,
  legalDocumentTypeOptions,
} from "../../../../types/legalDocument.ts";

export type LegalDocumentModalSubmitValue = Omit<
  CreateLegalDocumentInput,
  "id" | "customerId" | "createdAt"
>;

interface Props {
  value?: LegalDocument | null | undefined;
  opened?: boolean;
  onClose: () => void;
  onSubmit: (value: LegalDocumentModalSubmitValue) => void;
}

const LegalDocumentModal = ({
  value,
  opened = false,
  onClose,
  onSubmit,
}: Props) => {
  const form = useForm<Partial<LegalDocument>>({
    initialValues: {
      documentDate: value?.documentDate
        ? new Date(value.documentDate)
        : undefined,
      documentNo: value?.documentNo,
      documentFile: value?.documentFile,
      documentType: value?.documentType,
      expiryDate: value?.expiryDate ? new Date(value.expiryDate) : undefined,
      signedDocumentFile: value?.signedDocumentFile,
    },
    validate: {
      documentDate: isNotEmpty("Field is required"),
      documentNo: isNotEmpty("Field is required"),
      documentFile: isNotEmpty("Field is required"),
      documentType: isNotEmpty("Field is required"),
    },
    validateInputOnBlur: true,
  });

  const handleClose = useCallback(() => {
    form.reset();
    onClose();
  }, [form, onClose]);

  const handleSubmit = useCallback(() => {
    if (!form.isValid()) {
      return;
    }
    onSubmit({
      ...form.values,
      documentFile: form.values.documentFile?.filePath,
      signedDocumentFile: form.values.signedDocumentFile?.filePath ?? null,
    } as LegalDocumentModalSubmitValue);
    handleClose();
  }, [form, handleClose, onSubmit]);

  const handleFileUploaded = (
    name: keyof LegalDocument,
    files: FileResult[]
  ) => {
    if (name === "documentFile") {
      form.setValues({ documentFile: files[0] });
    }
    if (name === "signedDocumentFile") {
      form.setValues({ signedDocumentFile: files[0] });
    }
  };

  const handleRemoveFile = (name: keyof LegalDocument) => {
    if (name === "documentFile") {
      form.setValues({ documentFile: undefined });
    }
    if (name === "signedDocumentFile") {
      form.setValues({ signedDocumentFile: undefined });
    }
  };

  return (
    <>
      <Modal
        opened={opened}
        title={value ? "Edit Document" : "Add Document"}
        closeButtonProps={{
          size: 24,
          iconSize: 24,
        }}
        size="39em"
        scrollAreaComponent={ScrollArea.Autosize}
        onClose={handleClose}
      >
        <Flex direction="column" gap={20}>
          <Flex gap={12} direction="column">
            <DatePickerInput
              label="Document Date"
              placeholder="Select Document Date"
              icon={<SvgCalendar />}
              firstDayOfWeek={0}
              size="m"
              valueFormat="YYYY MMM DD"
              popoverProps={{ withinPortal: true, zIndex: 1000 }}
              clearable
              required
              {...form.getInputProps("documentDate")}
            />
            <TextInput
              required
              label="Document No"
              placeholder="Enter Document No"
              size="m"
              {...form.getInputProps("documentNo")}
            />
            <Select
              data={legalDocumentTypeOptions}
              required
              label="Document Type"
              placeholder="Select Document Type"
              size="m"
              withinPortal
              {...form.getInputProps("documentType")}
            />
            <Input.Wrapper required label="Document File" size="m" w="100%">
              <Dropzone
                maxFiles={1}
                maxWidth="unset"
                value={
                  form.values.documentFile ? [form.values.documentFile] : null
                }
                onUploadSuccess={(value) =>
                  handleFileUploaded("documentFile", value)
                }
                onRemove={() => handleRemoveFile("documentFile")}
              />
            </Input.Wrapper>
            <DatePickerInput
              label="Document Expiry Date"
              placeholder="Select Document Expiry Date"
              icon={<SvgCalendar />}
              firstDayOfWeek={0}
              size="m"
              valueFormat="YYYY MMM DD"
              popoverProps={{ withinPortal: true, zIndex: 1000 }}
              clearable
              {...form.getInputProps("expiryDate")}
            />
            <Input.Wrapper label="Signed Document File" size="m" w="100%">
              <Dropzone
                maxFiles={1}
                maxWidth="unset"
                value={
                  form.values.signedDocumentFile
                    ? [form.values.signedDocumentFile]
                    : null
                }
                onUploadSuccess={(value) =>
                  handleFileUploaded("signedDocumentFile", value)
                }
                onRemove={() => handleRemoveFile("signedDocumentFile")}
              />
            </Input.Wrapper>
          </Flex>
          <Flex gap={20} justify="end">
            <Button
              variant="outlineBlue"
              uppercase
              type="reset"
              onClick={onClose}
            >
              cancel
            </Button>
            <Button
              variant="filledBlue"
              uppercase
              type="submit"
              disabled={!form.isValid()}
              onClick={handleSubmit}
            >
              {value ? "save" : "add"}
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export default LegalDocumentModal;
