import { Badge } from "@mantine/core";
import { filter, sumBy } from "lodash";
import { useCallback, useMemo } from "react";

import { TableGroupData } from "../../../components/Table/types.ts";
import { LimitStatus, LimitType } from "../../../graphql/generated.ts";
import { LimitUtilization } from "../../../types/limit.ts";
import { calculatePercent, toCurrencyPrice } from "../../../utils/number.ts";

interface Props {
  data: LimitUtilization[] | null | undefined;
}

export const useLimitUtilizationTableData = ({
  data,
}: Props): TableGroupData => {
  const groups = [
    {
      id: "limitStatus",
      title: "Limit Status",
      columns: [{ accessor: "limitType", title: "Limit Type" }],
    },
    {
      id: "active",
      title: (
        <Badge color="green" size="sm" radius="xs" w="100%">
          Active
        </Badge>
      ),
      columns: [
        { accessor: "activeGeneral", title: "General" },
        { accessor: "activeProject", title: "Project" },
      ],
    },
    {
      id: "freeze",
      title: (
        <Badge color="blue" size="sm" radius="xs" w="100%">
          Freeze
        </Badge>
      ),
      columns: [
        { accessor: "freezeGeneral", title: "General" },
        { accessor: "freezeProject", title: "Project" },
      ],
    },
    {
      id: "inactive",
      title: (
        <Badge color="grey" size="sm" radius="xs" w="100%">
          Inactive
        </Badge>
      ),
      columns: [
        { accessor: "inactiveGeneral", title: "General" },
        { accessor: "inactiveProject", title: "Project" },
      ],
    },
  ];

  const calculateLimitNumber = useCallback(
    (status: LimitStatus, limitType: LimitType) => {
      return filter(data, { status, limitType }).length;
    },
    [data]
  );

  const calculateTotalLimit = useCallback(
    (status: LimitStatus, limitType: LimitType) => {
      return sumBy(filter(data, { status, limitType }), (l) =>
        Number(l.totalLimit)
      );
    },
    [data]
  );

  const calculateTotalRemaining = useCallback(
    (status: LimitStatus, limitType: LimitType) => {
      return sumBy(filter(data, { status, limitType }), (l) =>
        Number(l.remaining)
      );
    },
    [data]
  );

  const calculateTotalUtilized = useCallback(
    (status: LimitStatus, limitType: LimitType) => {
      return sumBy(filter(data, { status, limitType }), (l) =>
        Number(l.totalPaid)
      );
    },
    [data]
  );

  const calculateUtilizedPercentage = useCallback(
    (status: LimitStatus, limitType: LimitType) => {
      const totalLimit = calculateTotalLimit(status, limitType);
      const totalUtilized = calculateTotalUtilized(status, limitType);
      return `${calculatePercent(totalUtilized, totalLimit)}%`;
    },
    [calculateTotalLimit, calculateTotalUtilized]
  );

  const calculateUtilizationOver80 = useCallback(
    (status: LimitStatus, limitType: LimitType) => {
      const limits = filter(data, { status, limitType });
      return filter(limits, (l) => {
        return calculatePercent(l.totalPaid, l.totalLimit) > 80;
      }).length;
    },
    [data]
  );

  const calculateUtilizationUnder50 = useCallback(
    (status: LimitStatus, limitType: LimitType) => {
      const limits = filter(data, { status, limitType });
      return filter(limits, (l) => {
        return calculatePercent(l.totalPaid, l.totalLimit) < 50;
      }).length;
    },
    [data]
  );

  const rows = useMemo(() => {
    return [
      {
        limitType: "# of Limits",
        activeGeneral: calculateLimitNumber(
          LimitStatus.Active,
          LimitType.General
        ),
        activeProject: calculateLimitNumber(
          LimitStatus.Active,
          LimitType.Project
        ),
        freezeGeneral: calculateLimitNumber(
          LimitStatus.Freeze,
          LimitType.General
        ),
        freezeProject: calculateLimitNumber(
          LimitStatus.Freeze,
          LimitType.Project
        ),
        inactiveGeneral: calculateLimitNumber(
          LimitStatus.Inactive,
          LimitType.General
        ),
        inactiveProject: calculateLimitNumber(
          LimitStatus.Inactive,
          LimitType.Project
        ),
      },
      {
        limitType: "Total Limit",
        activeGeneral: toCurrencyPrice(
          calculateTotalLimit(LimitStatus.Active, LimitType.General)
        ),
        activeProject: toCurrencyPrice(
          calculateTotalLimit(LimitStatus.Active, LimitType.Project)
        ),
        freezeGeneral: toCurrencyPrice(
          calculateTotalLimit(LimitStatus.Freeze, LimitType.General)
        ),
        freezeProject: toCurrencyPrice(
          calculateTotalLimit(LimitStatus.Freeze, LimitType.Project)
        ),
        inactiveGeneral: toCurrencyPrice(
          calculateTotalLimit(LimitStatus.Inactive, LimitType.General)
        ),
        inactiveProject: toCurrencyPrice(
          calculateTotalLimit(LimitStatus.Inactive, LimitType.Project)
        ),
      },
      {
        limitType: "Total Remaining",
        activeGeneral: toCurrencyPrice(
          calculateTotalRemaining(LimitStatus.Active, LimitType.General)
        ),
        activeProject: toCurrencyPrice(
          calculateTotalRemaining(LimitStatus.Active, LimitType.Project)
        ),
        freezeGeneral: toCurrencyPrice(
          calculateTotalRemaining(LimitStatus.Freeze, LimitType.General)
        ),
        freezeProject: toCurrencyPrice(
          calculateTotalRemaining(LimitStatus.Freeze, LimitType.Project)
        ),
        inactiveGeneral: toCurrencyPrice(
          calculateTotalRemaining(LimitStatus.Inactive, LimitType.General)
        ),
        inactiveProject: toCurrencyPrice(
          calculateTotalRemaining(LimitStatus.Inactive, LimitType.Project)
        ),
      },
      {
        limitType: "Total Utilized",
        activeGeneral: toCurrencyPrice(
          calculateTotalUtilized(LimitStatus.Active, LimitType.General)
        ),
        activeProject: toCurrencyPrice(
          calculateTotalUtilized(LimitStatus.Active, LimitType.Project)
        ),
        freezeGeneral: toCurrencyPrice(
          calculateTotalUtilized(LimitStatus.Freeze, LimitType.General)
        ),
        freezeProject: toCurrencyPrice(
          calculateTotalUtilized(LimitStatus.Freeze, LimitType.Project)
        ),
        inactiveGeneral: toCurrencyPrice(
          calculateTotalUtilized(LimitStatus.Inactive, LimitType.General)
        ),
        inactiveProject: toCurrencyPrice(
          calculateTotalUtilized(LimitStatus.Inactive, LimitType.Project)
        ),
      },
      {
        limitType: "Utilized %",
        activeGeneral: calculateUtilizedPercentage(
          LimitStatus.Active,
          LimitType.General
        ),
        activeProject: calculateUtilizedPercentage(
          LimitStatus.Active,
          LimitType.Project
        ),
        freezeGeneral: calculateUtilizedPercentage(
          LimitStatus.Freeze,
          LimitType.General
        ),
        freezeProject: calculateUtilizedPercentage(
          LimitStatus.Freeze,
          LimitType.Project
        ),
        inactiveGeneral: calculateUtilizedPercentage(
          LimitStatus.Inactive,
          LimitType.General
        ),
        inactiveProject: calculateUtilizedPercentage(
          LimitStatus.Inactive,
          LimitType.Project
        ),
      },
      {
        limitType: "% Utilization >80%",
        activeGeneral: calculateUtilizationOver80(
          LimitStatus.Active,
          LimitType.General
        ),
        activeProject: calculateUtilizationOver80(
          LimitStatus.Active,
          LimitType.Project
        ),
        freezeGeneral: calculateUtilizationOver80(
          LimitStatus.Freeze,
          LimitType.General
        ),
        freezeProject: calculateUtilizationOver80(
          LimitStatus.Freeze,
          LimitType.Project
        ),
        inactiveGeneral: calculateUtilizationOver80(
          LimitStatus.Inactive,
          LimitType.General
        ),
        inactiveProject: calculateUtilizationOver80(
          LimitStatus.Inactive,
          LimitType.Project
        ),
      },
      {
        limitType: "%Utilization <50%",
        activeGeneral: calculateUtilizationUnder50(
          LimitStatus.Active,
          LimitType.General
        ),
        activeProject: calculateUtilizationUnder50(
          LimitStatus.Active,
          LimitType.Project
        ),
        freezeGeneral: calculateUtilizationUnder50(
          LimitStatus.Freeze,
          LimitType.General
        ),
        freezeProject: calculateUtilizationUnder50(
          LimitStatus.Freeze,
          LimitType.Project
        ),
        inactiveGeneral: calculateUtilizationUnder50(
          LimitStatus.Inactive,
          LimitType.General
        ),
        inactiveProject: calculateUtilizationUnder50(
          LimitStatus.Inactive,
          LimitType.Project
        ),
      },
    ];
  }, [
    calculateLimitNumber,
    calculateTotalLimit,
    calculateTotalRemaining,
    calculateTotalUtilized,
    calculateUtilizationOver80,
    calculateUtilizationUnder50,
    calculateUtilizedPercentage,
  ]);

  return [groups, rows];
};
