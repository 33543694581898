import { Flex, Select, SimpleGrid, TextInput } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { getInvoiceApiSort } from "../../../components/Invoice/InvoicesTable/utils.ts";
import Preloader from "../../../components/Preloader/Preloader.tsx";
import Table from "../../../components/Table/Table.tsx";
import { SortDirection, TableRow } from "../../../components/Table/types.ts";
import {
  InvoiceOrderBy,
  OrderBy,
  PaymentStatus,
} from "../../../graphql/generated.ts";
import { useCompanyOptions } from "../../../hooks/api/customer/useCompanyOptions.ts";
import { useInvoice } from "../../../hooks/api/invoice/useInvoice.ts";
import { useInvoices } from "../../../hooks/api/invoice/useInvoices.ts";
import { Customer } from "../../../types/customer.ts";
import { Invoice } from "../../../types/invoice/invoice.ts";
import { paymentStatusOptions } from "../../../types/transactionHistory.ts";
import {
  getSalesNameOptions,
  getSalesTeamOptions,
} from "../../../utils/company.ts";
import { getPagesCount } from "../../../utils/pagination.ts";
import InvoiceDetailTab from "../../ActivityManagementDetail/InvoiceDetailTab/InvoiceDetailTab.tsx";
import { useInvoicesTableData } from "./hooks/useInvoicesTableData.tsx";
import { InvoicesHistoryFiltersValue } from "./types.ts";
import { getInvoicesHistoryApiFilter } from "./utils.ts";

interface InvoicesHistoryListProps {
  customer: Pick<Customer, "id" | "name"> | null | undefined;
}

const InvoicesHistoryList = ({ customer }: InvoicesHistoryListProps) => {
  const [filter, setFilter] = useState<InvoicesHistoryFiltersValue>({});
  const [saleOrderNumber, setSaleOrderNumber] = useDebouncedState("", 500);
  const [invoiceNumber, setInvoiceNumber] = useDebouncedState("", 500);
  const [limitUsed, setLimitUsed] = useDebouncedState("", 500);

  const apiFilter = useMemo(() => {
    return getInvoicesHistoryApiFilter({
      ...filter,
      invoiceNumber,
      limitUsed,
      saleOrderNumber,
    });
  }, [filter, invoiceNumber, limitUsed, saleOrderNumber]);

  const [searchParams] = useSearchParams();
  const invoiceIdParam = searchParams.get("invoiceId");
  const invoiceId = invoiceIdParam ? Number(invoiceIdParam) : null;

  const [{ data: invoiceData, fetching: loadingInvoice }] = useInvoice({
    id: invoiceId,
  });

  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>();

  const [orderBy, setOrderBy] = useState<InvoiceOrderBy>({
    createdAt: OrderBy.Desc,
  });

  const [page, setPage] = useState<number>(1);

  const [{ data: invoicesData, fetching }] = useInvoices({
    filter: { ...apiFilter, customerId: { equals: customer?.id } },
    orderBy,
    page,
    pause: !customer,
  });
  const invoices = invoicesData?.invoices?.data;
  const pageCount = getPagesCount(invoicesData?.invoices?.total);

  const [{ data: companiesData }] = useCompanyOptions();
  const companies = companiesData?.customers?.data;

  useEffect(() => {
    setPage(1);
  }, []);

  const [columns, rows] = useInvoicesTableData({
    data: invoices,
  });

  const handleOnSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getInvoiceApiSort(sort));
    },
    [setOrderBy]
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const handleFilterChange = useCallback((key: string, value: unknown) => {
    setFilter((prevFilter) => {
      return { ...prevFilter, [key]: value };
    });
  }, []);

  const handleInvoiceClick = (record: TableRow) => {
    if (!invoices) {
      return;
    }
    const invoice = invoices.find((item: Invoice) => item.id === record.id);
    if (!invoice) {
      return;
    }
    setSelectedInvoice(invoice);
  };

  useEffect(() => {
    if (invoiceData) {
      setSelectedInvoice(invoiceData.invoice);
    }
  }, [invoiceData]);

  if (selectedInvoice) {
    return (
      <InvoiceDetailTab
        data={selectedInvoice}
        onBreadcrumbsClick={() => {
          setSelectedInvoice(null);
        }}
      />
    );
  }

  return (
    <Flex direction="column" gap={16}>
      <Preloader loading={loadingInvoice} />
      <SimpleGrid
        w="100%"
        cols={4}
        breakpoints={[
          { maxWidth: "sm", cols: 2 },
          { maxWidth: "lg", cols: 3 },
        ]}
        spacing={20}
        verticalSpacing={12}
      >
        <TextInput
          size="m"
          label="SO No"
          placeholder="SO No"
          onChange={(event) => {
            setSaleOrderNumber(event.target.value);
          }}
        />
        <TextInput
          size="m"
          label="Limit Used"
          placeholder="Limit Used"
          onChange={(event) => {
            setLimitUsed(event.target.value);
          }}
        />
        <Select
          size="m"
          clearable
          searchable
          label="Sales Name"
          placeholder="Select Sales Name"
          data={getSalesNameOptions(companies)}
          onChange={(value) => handleFilterChange("salesName", value)}
        />
        <TextInput
          size="m"
          label="Invoice No"
          placeholder="Invoice No"
          onChange={(event) => {
            setInvoiceNumber(event.target.value);
          }}
        />
        <Select
          size="m"
          clearable
          label="Payment Status"
          placeholder="Select Payment Status"
          data={paymentStatusOptions}
          onChange={(value: PaymentStatus) =>
            handleFilterChange("paymentStatus", value)
          }
        />
        <Select
          size="m"
          clearable
          searchable
          label="Sales Team"
          placeholder="Select Sales Team"
          data={getSalesTeamOptions(companies)}
          onChange={(value) => handleFilterChange("salesTeam", value)}
        />
      </SimpleGrid>
      <Table
        columns={columns}
        rows={rows}
        defaultSort={{
          columnAccessor: "createdAt",
          direction: SortDirection.desc,
        }}
        pagination={{
          pageCount: pageCount,
          page: page,
          onPageChange: handlePageChange,
        }}
        loading={fetching}
        onSortChange={handleOnSortChange}
        onRowClick={handleInvoiceClick}
      />
    </Flex>
  );
};

export default InvoicesHistoryList;
