import { useMemo } from "react";
import { Link } from "react-router-dom";

import MultiFieldCard from "../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { DatabaseMetadataPopover } from "../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import { TableEmptyValue } from "../../components/Table/constants.tsx";
import { AppRoute } from "../../constants.ts";
import { ClikResultStatus } from "../../graphql/generated.ts";
import {
  LimitRequestDetail,
  toHumanReadableTopProductCategory,
} from "../../types/limitRequest/limitRequest.ts";
import { toLocalDate } from "../../utils/date.ts";
import { toPrice } from "../../utils/number.ts";
import { toHumanReadable } from "../../utils/string.ts";

interface Props {
  limitRequest: LimitRequestDetail | null | undefined;
  loading: boolean;
}

export default function LimitRequestInfoCard({ limitRequest, loading }: Props) {
  const limitRequestInfo = useMemo(() => {
    return [
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "LimitRequest",
              fieldName: "createdAt",
            }}
            position="right"
          >
            Limit Requested Date
          </DatabaseMetadataPopover>
        ),
        content: toLocalDate(limitRequest?.createdAt),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "LimitRequest",
              fieldName: "limitRequestAmount",
            }}
            position="right"
          >
            Requested Amount
          </DatabaseMetadataPopover>
        ),
        content: toPrice(limitRequest?.limitRequestAmount),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "LimitRequest",
              fieldName: "tenorRequest",
            }}
            position="right"
          >
            Requested Tenor
          </DatabaseMetadataPopover>
        ),
        content: limitRequest?.tenorRequest,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "LimitDetail",
              fieldName: "limitType",
            }}
            position="right"
          >
            Limit Type
          </DatabaseMetadataPopover>
        ),
        content: toHumanReadable(limitRequest?.limitDetail?.limitType),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "LimitRequest",
              fieldName: "topProductCategory",
            }}
            position="right"
          >
            TOP Product Category
          </DatabaseMetadataPopover>
        ),
        content:
          limitRequest?.topProductCategory &&
          toHumanReadableTopProductCategory(limitRequest.topProductCategory),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "LimitDetail",
              fieldName: "status",
            }}
            position="right"
          >
            Limit Status
          </DatabaseMetadataPopover>
        ),
        content: toHumanReadable(limitRequest?.limitDetail?.status),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "LimitRequest",
              fieldName: "suratPengajuanLimit",
            }}
            position="right"
          >
            Surat Pengajuan Limit
          </DatabaseMetadataPopover>
        ),
        content: limitRequest?.suratPengajuanLimit?.url && (
          <Link to={limitRequest?.suratPengajuanLimit.url} target="_blank">
            Download
          </Link>
        ),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "LimitRequest",
              fieldName: "formLOI",
            }}
            position="right"
          >
            Form LOI
          </DatabaseMetadataPopover>
        ),
        content: limitRequest?.formLOI?.url && (
          <Link to={limitRequest?.formLOI.url} target="_blank">
            Download
          </Link>
        ),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikResult",
              fieldName: "createdAt",
            }}
            position="right"
          >
            CLIK Result Date
          </DatabaseMetadataPopover>
        ),
        content: toLocalDate(limitRequest?.lastClikResult?.createdAt),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikResult",
              fieldName: "id",
            }}
            position="right"
          >
            CLIK Result
          </DatabaseMetadataPopover>
        ),
        content:
          limitRequest?.lastClikResult &&
          limitRequest.lastClikResult.clikStatus ===
            ClikResultStatus.Success ? (
            <Link
              to={`${AppRoute.ClikResult}/${limitRequest.lastClikResult?.id}`}
            >
              View
            </Link>
          ) : (
            TableEmptyValue
          ),
      },
    ];
  }, [limitRequest]);

  return (
    <MultiFieldCard
      label={
        limitRequest
          ? `Limit Request - ${limitRequest.limitName}`
          : "Limit Request"
      }
      variant="blue"
      loading={loading}
      items={limitRequestInfo}
    />
  );
}
