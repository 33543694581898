import { Flex } from "@mantine/core";

import Layout from "../../components/Layout/Layout.tsx";
import Title from "../../components/Title/Title.tsx";
import CreditLimitFunnelTable from "./CreditLimitFunnel/CreditLimitFunnelTable.tsx";
import CustomersUtilizationLess50Table from "./CustomersUtilizationLess50Table.tsx";
// import CustomersUtilizationLess50Table from "./CustomersUtilizationLess50Table.tsx";
import CustomersUtilizationOver80Table from "./CustomersUtilizationOver80Table.tsx";
import LimitUtilizationTable from "./LimitUtilizationTable.tsx";

const RiskFunnelDashboardPage = () => {
  return (
    <Layout>
      <Flex direction="column" gap={{ base: 16, sm: 24 }}>
        <Title>Risk Funnel Dashboard</Title>
        <CreditLimitFunnelTable />
        <LimitUtilizationTable />
        <CustomersUtilizationOver80Table />
        <CustomersUtilizationLess50Table />
      </Flex>
    </Layout>
  );
};

export default RiskFunnelDashboardPage;
