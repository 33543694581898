import { Badge } from "@mantine/core";
import { DataTableColumn } from "mantine-datatable";
import { useCallback, useMemo } from "react";

import Spoiler from "../../../../../components/Spoiler/Spoiler.tsx";
import Table from "../../../../../components/Table/Table.tsx";
import { TableData, TableRow } from "../../../../../components/Table/types.ts";
import { CollateralStatusLog } from "../../../../../types/collateral.ts";
import { toLocalDateTime } from "../../../../../utils/date.ts";
import { toHumanReadable } from "../../../../../utils/string.ts";
import { getUserDisplayName } from "../../../../../utils/user.ts";

interface Props {
  data: CollateralStatusLog[] | null | undefined;
}

export function useGiroManagementLogTableData({ data }: Props): TableData {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "status",
    },
    {
      accessor: "logs",
    },
  ];

  const renderLogsColumn = useCallback((item: CollateralStatusLog) => {
    return (
      <Table
        mih={0}
        minWidth="100%"
        highlightOnHover={false}
        styles={{ header: { zIndex: 0, color: "red" } }}
        columns={[
          {
            accessor: "id",
            hidden: true,
          },
          {
            accessor: "createdAt",
            title: "Update Date",
          },
          {
            accessor: "statusChange",
            title: "Status Changes",
          },
          {
            accessor: "notes",
            title: "Notes",
            width: 300,
          },
          {
            accessor: "createdBy",
            title: "User",
          },
        ]}
        rows={[
          {
            id: item.id,
            createdAt: toLocalDateTime(item.createdAt),
            statusChange: item.oldStatus
              ? `${toHumanReadable(item.oldStatus)} -> ${toHumanReadable(
                  item.newStatus
                )}`
              : toHumanReadable(item.newStatus),
            notes: item.notes && (
              <Spoiler maxStringLength={300} text={item.notes} />
            ),
            createdBy: getUserDisplayName(item.createdBy),
          },
        ]}
      />
    );
  }, []);

  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        id: item.id,
        status: (
          <Badge variant="blueFilled" w="100%" size="m">
            {toHumanReadable(item.newStatus)}
          </Badge>
        ),
        logs: renderLogsColumn(item),
      };
    });
  }, [data, renderLogsColumn]);

  return [columns, rows];
}
